html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #ffffff;
  font-size: 1em;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  min-height: 100vh;
}
#root {
  width: 100%;
  height: 100%;
}
.header {
  padding: 0;
  width: 100%;
  height: 8%;
  display: flex;
}
.header .logo {
  width: 20%;
  color: #fff;
  font-size: 1em;
  font-weight: bold;
  text-align: center;
}
.header .func {
  width: 80%;
  font-size: 1em;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: end;
}
.header .func .itme {
  width: 10%;
  color: #fff;
  text-align: center;
}
.content {
  top: 8%;
  width: 100%;
  height: 84%;
  font-size: 1em;
}
.footer {
  bottom: 0;
  width: 100%;
  height: 8%;
  text-align: center;
  font-size: 1em;
}
