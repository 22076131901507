// 定义变量
@html-bg-color: #ffffff; // 定义HTML背景颜色
@html-font-size: 1em;// 定义基础字体大小

@header-font-color: #fff; // 定义基础字体颜色

@white-font-color: #fff; 
@black-font-color:#000;

@black-bg-color: #000; 

// 重置HTML和BODY的默认样式
html, body {
  width: 100%;
  height: 100%;
  margin: 0; // 移除默认边距
  padding: 0; // 移除默认内边距
  background-color: @html-bg-color; // 设置背景颜色
  font-size: @html-font-size; // 设置基础字体大小
  // 使用border-box模型，包括padding和border在内的宽度和高度
  .box-sizing(border-box);
}

// 辅助Mixin，用于设置box-sizing属性
.box-sizing(@type: border-box) {
  box-sizing: @type;
  -moz-box-sizing: @type;
  -webkit-box-sizing: @type;
}

// 确保body元素撑满整个视口
body {
  display: flex; // 使用Flexbox布局
  flex-direction: column; // 设置主轴方向为垂直
  justify-content: space-between; // 空间分布，如果需要
  align-items: stretch; // 子元素水平填充
  min-height: 100vh; // 确保至少为视口高度
}

#root{
  width: 100%;
  height: 100%;
}
@primary-color: #1DA57A;@link-color: #1DA57A;@border-radius-base: 2px;